.container{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 300;
    display: flex;
    pointer-events: none;
}

.container h4 {
    color: var(--dark-theme-text-color);
    margin: 0 auto;
}
