.container {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
}

.minimal-controls-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
}

.button-container {
    display: flex;
    gap: 4px;
}

.container :global(.ant-input-number) {
    background-color: transparent;
}

.container :global(.ant-input-number input) {
    color: var(--dark-theme-viewport-button-color);
    padding: 0 3px;
}

/* Hide the up/down buttons in the time input box */
.container :global(.ant-input-number-handler-wrap) {
    display: none;
}

.container .slider {
    flex: 3;
    margin: auto 10px;
}

.container .slider :global(.ant-slider-track) {
    background-color: var(--dark-theme-slider-handle-color);
}

.container .slider :global(.ant-slider-track),
.container .slider :global(.ant-slider-rail) {
    border-radius: 3px;
}

.container .slider :global(.ant-slider-handle) {
    background-color: var(--dark-theme-slider-handle-color);
    border: none;
    height: 10px;
    width: 10px;
    margin-top: -3.5px;
}

.container .slider :global(.ant-slider-handle):focus {
    box-shadow: none;
}

.time {
    background-color: var(--dark-theme-viewport-button-bg);
    color: var(--dark-theme-viewport-button-color);
    height: 26px;
    min-width: 100px;
    padding: 1px 7px 4px 7px;
    border-radius: 3px;
    text-align: center;
    margin-right: 4px;
}

.lastFrameTime {
    color: var(--dark-theme-dim-color);
}

.remove-border {
    border: none;
}