.nav-button::after {
  animation: none;
}

.nav-button.action-button:global(.ant-btn) {
    background-color: var(--dark-theme-action-button-bg);
    border: none;
    color: var(--dark-theme-action-button-color);
    padding: 4px 8px;
}

.nav-button.action-button:global(.ant-btn:hover) {
    color: var(--dark-theme-action-button-hover-color);
}

.nav-button.action-button:global(.ant-btn:focus-visible) {
    color: var(--dark-theme-action-button-focus-color);
    outline: 1px solid var(--dark-theme-primary-button-focus-outline);
}

.nav-button.action-button:global(.ant-btn).disabled {
    color: var(--dark-theme-action-button-disabled-color);
    cursor: not-allowed;
}

.nav-button.primary-button:global(.ant-btn) {
    background-color: var(--dark-theme-primary-button-bg);
    border-color: var(--dark-theme-primary-button-border);
    border-radius: 3px;
    color: var(--dark-theme-primary-button-color);
}

.nav-button.primary-button:global(.ant-btn:hover) {
    color: var(--dark-theme-primary-button-hover-color);
    background-color: var(--dark-theme-primary-button-hover-bg);
    border-color: var(--dark-theme-primary-button-hover-border);
}

.nav-button.primary-button:global(.ant-btn:focus-visible) {
    color: var(--dark-theme-primary-button-focus-color);
    background-color: var(--dark-theme-primary-button-focus-bg);
    border-color: var(--dark-theme-primary-button-focus-border);
    outline: 1px solid var(--dark-theme-primary-button-focus-outline);
    outline-offset: 2px;
}

.nav-button.primary-button:global(.ant-btn).disabled {
    color: var(--dark-theme-primary-button-disabled-color);
    background-color: var(--dark-theme-primary-button-disabled-bg);
    border-color: var(--dark-theme-primary-button-disabled-border);
    cursor: not-allowed;
}

.nav-button.secondary-button:global(.ant-btn) {
    background-color: var(--dark-theme-secondary-button-bg);
    border-color: var(--dark-theme-secondary-button-border);
    border-radius: 3px;
    color: var(--dark-theme-secondary-button-color);
}

.nav-button.secondary-button:global(.ant-btn:hover) {
    color: var(--dark-theme-secondary-button-hover-color);
    background-color: var(--dark-theme-secondary-button-hover-bg);
    border-color: var(--dark-theme-secondary-button-hover-border);
}

.nav-button.secondary-button:global(.ant-btn:focus-visible) {
    color: var(--dark-theme-secondary-button-focus-color);
    background-color: var(--dark-theme-secondary-button-focus-bg);
    border-color: var(--dark-theme-secondary-button-focus-border);
    outline: 1px solid var(--dark-theme-secondary-button-focus-outline);
    outline-offset: 2px;
}

.nav-button.secondary-button:global(.ant-btn).disabled {
    color: var(--dark-theme-secondary-button-disabled-color);
    background-color: var(--dark-theme-secondary-button-disabled-bg);
    border-color: var(--dark-theme-secondary-button-disabled-border);
    cursor: not-allowed;
}

.nav-button.dropdown-item-button:global(.ant-btn) {
    background-color: inherit;
    border: inherit;
}