.container {
  margin: 1em;
  font-size: 16px;
}

.container,
.container .heading {
  color: var(--dark-theme-sidebar-text);
}

.heading {
  font-weight: 600;
  margin-bottom: 1.5em;
}

.list {
  padding-left: 20px;
  font-weight: 200;
}