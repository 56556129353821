.container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: fit-content;
    margin: auto;
}

.container img {
    max-width: 800px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;
}

.container :global(.ant-collapse) {
    width: 740px;
    display: block;
    margin: 1em auto 2em auto;
    background-color: var(--light-theme-visual-glossary-header-bg);
    border: transparent;
    border-radius: 3px;
    font-size: 20px;
    box-shadow: 3px 3px 10px var(--text-gray);
}

.container :global(.ant-collapse > .ant-collapse-item) {
    border: none;
}

.container :global(.ant-collapse-content) {
    background-color: var(--light-theme-visual-glossary-content-bg);
    font-size: 16px;
    border: none;
    height: 417px;
    overflow: scroll;
}

.topLevelList > li {
    margin-bottom: 0.5em;
}