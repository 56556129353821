/* Primary and secondary button colors differ within modals */

/* primary buttons */
.light-theme :global(.primary-button),
.light-theme :global(.primary-button):focus {
    background-color: var(--light-theme-btn-primary-bg);
    border: var(--light-theme-btn-primary-border);
    color: var(--light-theme-btn-primary-color);
}

.light-theme :global(.primary-button:hover) {
    background-color: var(--light-theme-btn-primary-hover-bg);
    border: var(--light-theme-btn-primary-hover-border);
    color: var(--light-theme-btn-primary-hover-color);
}

.light-theme :global(.primary-button[disabled]) {
    background: var(--light-theme-btn-primary-disabled-bg);
    border: var(--light-theme-btn-primary-disabled-border);
    color: var(--light-theme-btn-primary-disabled-color);
}

/* secondary buttons: typically: close, ok, cancel */
.light-theme :global(.secondary-button),
.light-theme :global(.secondary-button):focus {
    background-color: var(--light-theme-btn-secondary-bg);
    border-color: var(--light-theme-btn-secondary-border);
    color: var(--light-theme-btn-secondary-color);
}

.light-theme :global(.secondary-button:hover) {
    background-color: var(--light-theme-btn-secondary-hover-bg);
    border-color: var(--light-theme-btn-secondary-hover-border);
    color: var(--light-theme-btn-secondary-hover-color);
}

.light-theme :global(.secondary-button[disabled]) {
    background: var(--light-theme-btn-secondary-disabled-bg);
    border: var(--light-theme-btn-secondary-disabled-border);
    color: var(--light-theme-btn-secondary-disabled-color);
}
