.container {
    position: absolute;
    background-color: var(--light-theme-bg);
    color: var(--light-theme-color);
    height: calc(100vh - var(--header-height));
    width: 100%;
    z-index: 1000;
}

.form-content {
    width: 900px;
    padding-left: 30px;
    padding-top: 30px;
}

.title {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 0em;
    padding-bottom: 0px;
}

.section-header {
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 15px;
}

.select-title {
    font-size: 1.08em;
    margin-bottom: 0px;
}

.selector-box, .title-input {
    margin-right: 20px;
    width: 200px;
}

/* two parent classes needed here to overide specificity of antd classes */
.upload-container .selector-box :global(.ant-select-selector), .title-input {
    border-radius: 3px;
    border: 1px solid var(--light-theme-select-border-color);
}

.upload-container {
    display: flex;
    max-height: 32px;
}

.upload {
    display: flex;
}

.upload :global(.ant-upload-span) {
    background-color: var(--overlay-purple);
}

.upload .rendered-file-name {
    height: 100%;
    margin-top: 6px;
    display: block;
}

.upload :global(.ant-upload-text-icon) {
    display: none;
}

.upload-container .remove-file-icon {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    min-width: 16px;
}

.remove-file-icon svg {
    fill: var(--cancel-icon-color);
}

.optional-title {
    font-size: 1.08em;
    margin-top: 22px;
    margin-bottom: 0px;
}

.divider {
    background-color: var(--light-theme-divider);
}

.form-content button {
    margin-right: 15px;
    min-width: 80px;
    max-height: 30px;
}

.form-content span svg path {
    /* this makes the down arrow in the select box visible  */
    color: var(--light-theme-btn-primary-border);
}

.container .loading-icon,
.container.loading-text {
    text-align: center;
}
