.container {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 20px;
    justify-content: space-evenly;
    gap: 10px;
    margin-bottom: 20px;
}

.zoom-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.radio-group {
    display: flex;
    flex-direction: column;
}