.container {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    max-width: calc(100vw - 562px);
    min-width: 0;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag {
    margin-left: 5px;
    margin-right: 0px;
    border-radius: 2px;
    padding-right: 5px;
    padding-left: 5px;
    height: 20px;
    font-size: 12px;
    border-color: var(--dark-theme-version-badge-purple);
    background-color: var(--dark-theme-version-badge-purple);
}