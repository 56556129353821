.container, .container:global(.ant-upload.ant-upload-drag){
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 300;
    background-color: rgba(181, 159, 246, 0.7);
}

.container .loading-icon, .container.loading-text {
    text-align: center;
}

.container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}