.container {
    margin: auto;
    text-align: center;
}

/* cspell:disable-next-line */
.container :global(.xtitle), :global(.ytitle) {
    font-weight: 200 !important;
}

/* cspell:disable-next-line */
.container :global(.legendtext), :global(.xtick), :global(.ytick) {
    font-weight: 200;
}

.container :global(.js-plotly-plot) {
    margin-bottom: 5px;
}

/* this global class name is applied by plotly */
.container :global(.main-svg) {
    border-radius: 6px;
}