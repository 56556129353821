/* Default and primary button colors differ within modals */
.modal :global(.ant-modal-content) {
    /* prevents modal body color rendering under header/footer at corners */
    color: var(--light-theme-modal-color);
}

.modal :global(.ant-modal-header) {
    display: flex;
    justify-content: center;
    padding: 26px 24px 14px;
}

.modal :global(.ant-modal-title) {
    font-size: 21px;
    align-items: center;
    display: flex;
    font-size: 21px;
    font-weight: 400;
}

.modal :global(.close-icon::after) {
    font-size: 16px;
    color: var(--light-theme-modal-color);
    cursor: pointer;
    display: flex;
    padding-bottom: 10px;
    position: absolute;
    right: 24px;
}

.modal :global(.close-icon):hover::after {
    color: var(--light-theme-modal-close-icon-hover);
}

.modal :global(.ant-modal-body) {
    font-size: 16px;
    padding: 16px 24px 26px 24px;
}

.modal :global(.ant-modal-body) p {
    margin-bottom: 0px;
}

.modal.divider :global(.ant-modal-body) {
    padding: 24px;
}

.modal :global(.ant-modal-footer) {
    background-color: var(--light-theme-modal-bg);
    padding: 0px;
}

.footer-button-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    margin: 0px;
    padding: 20px 24px;
}

.modal :global(.ant-modal-footer) button {
    height: 32px;
    width: 83px;
}

.modal :global(.ant-input),
.modal :global(.ant-input):global(.ant-input-disabled) {
    border-radius: 3px;
    background-color: var(--light-theme-modal-input-bg);
    border-color: var(--light-theme-modal-input-border);
    color: var(--light-theme-modal-input-color);
}

.modal :global(.ant-input):global(.ant-input-disabled) {
    background-color: var(--light-theme-modal-disabled-bg);
    color: var(--light-theme-modal-input-disabled-color);
}

.modal :global(.ant-input-affix-wrapper) {
    border: 1px solid var(--light-theme-modal-input-border);
    border-radius: 3px;
}

.modal :global(.ant-divider) {
    left: 0;
    margin: 0px;
    position: absolute;
    background-color: var(--light-theme-modal-divider);
}

.modal :global(.ant-checkbox-inner),
.modal :global(.ant-checkbox)::after {
    background-color: transparent;
    border: 1px solid var(--light-theme-checkbox-color) !important;
}
