.container {
    display: block;
    background-color: var(--footer-bg);
    padding-top: 1.5em;
    padding-bottom: 1em;
}

.container :global(.ant-row) {
    width: 75%;
    margin: 0 auto 1.5em auto;
    display: flex;
    font-weight: 200;
    color: var(--footer-text);
}

.link-column {
    text-align: center;
    flex-grow: 1;
    margin: 0 1em;
    border-bottom: 1px solid var(--footer-text-border-bottom);
    padding: 1em;
}

.container :global(#ot-sdk-btn.ot-sdk-show-settings) {
    font-weight: 200;
    font-size: 14px;
    color: var(--footer-link);
    border: none;
    padding: 0;
}

.container :global(#ot-sdk-btn.ot-sdk-show-settings):hover, :global(#ot-sdk-btn.ot-sdk-show-settings):active, :global(#ot-sdk-btn.ot-sdk-show-settings):focus {
    color: var(--footer-link);
    background-color: transparent;
}