.custom-radio-group
    :global(.ant-radio-checked)
    :global(.ant-radio-inner)::after {
    background-color: var(--light-theme-modal-color);
}

.custom-radio-group :global(.ant-radio-inner) {
    background-color: var(--light-theme-white-bg);
}

.custom-radio-group :global(.ant-radio-checked) :global(.ant-radio-inner) {
    border-color: var(--light-theme-modal-color);
}

.custom-radio-group :global(.ant-radio-wrapper) span {
    align-items: center;
    display: flex;
    gap: 7px;
    font-size: 16px;
}

.time-input {
    text-align: right;
    width: 64px;
}

.time-input.disabled {
    color: var(--medium-grey);
    background-color: var(--light-purple);
}

/* this input is functionally disabled but styled as though it is not */
.url-input[disabled]:global(.ant-input) {
    flex-grow: 1;
    color: var(--light-theme-modal-input-color);
    background-color: var(--light-theme-modal-input-bg);
    cursor: auto;
    direction: rtl;
    width: 395px;
}

.embed-header {
    display: flex;
    justify-content: space-between;
}

.settings-button {
    border: none;
    background-color: var(--light-theme-bg);
    font-size: 16px;
    cursor: pointer;
}

.settings-button:hover {
    font-weight: 500;
}

.embed-settings {
    transition: height 0.3s;
    height: 0px;
}

.embed-settings.open {
    margin-bottom: 10px;
    height: 56px;
}

.accent-text {
    font-size: 16px;
    font-weight: 600;
}

.number-inputs {
    text-align: right;
    width: 64px;
}

.constrain-proportions {
    align-items: center;
    display: flex;
    gap: 7px;
    padding-left: 10px;
}

.embed-input[disabled]:global(.ant-input) {
    height: 98px;
    color: var(--light-theme-modal-input-color);
    background-color: var(--light-theme-modal-input-bg);
    cursor: auto;
    width: 502px;
    scrollbar-width: thin;
}

.share-container h4 {
    font-size: 19px;
    margin: 0px;
}