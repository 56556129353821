.record {
    display: flex;
    gap: 4px;
    justify-content: space-evenly;
}

.icon-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.red-circle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: var(--dark-theme-record-button-red);
    box-shadow: 0 0 0 0 var(--dark-theme-record-button-red);
}

.animate {
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 #ff5252b3;
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px #ff525200;
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 #ff525200;
    }
}

.status-container {
    position: relative;
}

.recording-status {
    margin-top: 5px;
    position: absolute;
}
