.content {
    display: block;
    margin: auto;
    padding: 2em;
    font-size: 18px;
}

.text {
    max-width: 1200px;
    margin: auto;
}

.intro {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 20px;
}

.list-header {
    font-size: 20px;
}

.drag-drop-image {
    margin: 1em;
    border-radius: 3px;
    box-shadow: 3px 3px 20px var(--text-gray);
}

/* Prevents image from being huge on larger screens */
@media screen and (min-width: 576px) {
    .drag-drop-image {
        width: 400px;
    }
}

.approach-block {
    margin-bottom: 2em;
}

/* Makes font size smaller and reduces whitespace on mobile */
@media screen and (max-width: 480px) {
    .approach-block {
        font-size: 0.7rem;
        padding: 0;
    }

    .approach-block ol, ul {
        padding-left: 1em;
    }

    .approach-block .list-header {
        font-size: 0.8rem;
    }
}

:global(.ant-typography code) {
    margin-top: 2em;
    margin-bottom: 2em;
}