@import "../../styles/constants.css";

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--dark-theme-body-bg);
    color: var(--dark-theme-text-color);
}
.container :global(.ant-layout-content) {
    height: calc(100vh - var(--header-height));
    /* Prevents a light gray background from flashing upon collapsing the right sidebar */
    background: var(--dark-theme-body-bg);
}

.container.embed :global(.ant-layout-content) {
    height: 100vh;
    /* Prevents a light gray background from flashing upon collapsing the right sidebar */
    background: var(--dark-theme-body-bg);
}

.content {
    position: relative;
    height: 100%;
}

.container :global(.ant-btn-ghost) {
    color: var(--dark-theme-primary-color);
    border-color: var(--dark-theme-primary-color);
}