.upload-modal .extra-info {
    margin-top: .25rem;
    margin-bottom: 0rem;
    color: var(--light-theme-modal-supplemental-text);
    font-size: 14px;
}

.upload-modal .extra-info a:focus {
    opacity: 0.7;
}

.upload-modal :global(.ant-form-item) {
    margin-bottom: 0;
}

.upload-modal :global(.ant-input-suffix svg) {
    color: var(--light-theme-modal-supplemental-text);
}

.upload-modal :global(.ant-tabs-nav) {
    margin-bottom: 25px;
}

.upload-modal :global(.ant-tabs-nav)::before {
    content: none;
}

.upload-modal :global(.ant-tabs .ant-tabs-tab) {
    padding: 0;
    font-size: 16px;
}

.upload-modal :global(.ant-tabs .ant-tabs-tab-active) {
    font-weight: 400;
}
