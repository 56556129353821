.container {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.bottom-controls-container {
    position: absolute;
    display: flex;
    gap: 4px;
    bottom: 0;
    margin-bottom: 20px;
    right: 25%;
    width: 50%;
}

.full-width-bottom-controls {
    right: 5%;
    left: 5%;
    width: 90%;
}
