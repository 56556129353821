.container {
    position: absolute;
    bottom: 0;
    right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 72px;
    background-color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 3px;
}

.text {
    font-size: 12px;
    color: var(--dark-theme-dim-color);
}

.container img {
    margin-top: -5px;
}